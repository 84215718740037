<template lang="pug">
	.basic-box-shadow.border-rounded.foreground-color.mr-4.connect-resource.cursor-pointer.bot-card(:class="{'h208': status != 'notConnected'}" @click="status != 'notConnected' ? $router.push({name: 'settings_bot'}) : ''")
		.d-flex.justify-content-end.align-items-center.position-relative.connect-resource__top(:style="statusStyle")

			span.vuetable__status.connect-resource__status(v-if="status == 'connected'" v-text="$t('resources.status.connected')" :class="{'text-success connect-resource__status-success': true}")
			span.vuetable__status.connect-resource__status(v-if="status == 'noContact'" v-text="$t('resources.status.noContact')" :class="{'text-error': true}")

			div(v-if="status == 'noContact'")
				.position-relative.ml-10
					span.d-flex.justify-content-center.align-items-center.cursor-pointer.rounded-circle.connect-resource__question(
						:id="!isMobile ? `connectResourceTooltip` : ''"
						@click="isMobile ? $bvModal.show(`connectResourceTooltip`) : ''"
						@click.stop
					) ?

				modal-tooltip(
					:id="`connectResourceTooltip`"
					:title="$t('resources.status.noContact')"
					:text="`${$t('bots.checkAvailability2')}&nbsp;<a href='https://docs.paywall.pw/rus/neirobot/net-svyazi-s-botom' target='_blank'>${$t('bots.instruction')}</a>.`"
					:textMobile="`${$t('bots.checkAvailability2')}&nbsp;<a href='https://docs.paywall.pw/rus/neirobot/net-svyazi-s-botom' target='_blank'>${$t('bots.instruction')}</a>.`"
					:classTooltip="'small-tooltip'"
				)

			.connect-resource__social
				img.rounded-circle.connect-resource__social-image(v-if="social == 'Telegram' && status == 'notConnected'" src="/assets/img/icons/telegram.svg" :alt="social" width="83" height="83")
				img.rounded-circle.connect-resource__social-image(v-if="image" :src="image" alt="Bot Avatar" width="83" height="83")
				span.connect-resource__letter.connect-resource__social-image(v-if="status != 'notConnected' && !image") {{ `${firstName.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').slice(0, 1)}` }}
				svg.connect-resource__social-error(v-if="status == 'noContact'" width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					path(d='M29.6673 16.0002C29.6673 8.47893 23.5219 2.3335 16.0007 2.3335C8.47941 2.3335 2.33398 8.47893 2.33398 16.0002C2.33398 23.5214 8.47941 29.6668 16.0007 29.6668C23.5219 29.6668 29.6673 23.5214 29.6673 16.0002Z' :fill="isDarkColor ? '#27282A' : '#FFFFFF'" stroke='#FF7C7C' stroke-width='4' stroke-linecap='round' stroke-linejoin='round')
					path(d='M16 11.3335V17.1668' stroke='#FF7C7C' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M15.9941 20.6665H16.0046' stroke='#FF7C7C' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round')

		.d-flex.flex-column.justify-content-between.p-4.connect-resource__bottom
			.d-flex.align-items-end.mt-4
				h2.b1.text-bold(v-if="social == 'Telegram' && status == 'notConnected'") {{ $t('social.tg') }}
				.d-flex.align-items-center.mb-1(v-else)
					img(src="/assets/img/icons/telegram.svg" :alt="social" width="27" height="27")
					span.b1.text-bold.overflow-wrap.connect-resource__login @{{ login }}

			div(v-if="social == 'Telegram' && status == 'notConnected'")
				span.btn.w-100.connect-resource__button(@click.stop='goToAddBot')
					span.b3 {{ $t('button.connect') }}

			.d-flex.align-items-center(v-if="social == 'Telegram' && status != 'notConnected'")
				a.text-decoration-none(:href="`https://t.me/${login}`" target='_blank' @click.stop)
					span.btn.connect-resource__button.bot-card__start-chat {{ $t('bots.startChat') }}
				pw-icon-copy(:text="`@${login}`", :textSuccess="`😊 &nbsp;&nbsp;&nbsp;${this.$t('bot.usernameCopied')}`" @click.native.stop)
				a(:href="`https://t.me/${login}`" target='_blank' @click.stop)
					i.fontello-icon-link
				svg.bot-card__settings(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
					path(fill-rule='evenodd' clip-rule='evenodd' d='M12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M2 11.1194C2 10.0794 2.85 9.21945 3.9 9.21945C5.71 9.21945 6.45 7.93945 5.54 6.36945C5.02 5.46945 5.33 4.29945 6.24 3.77945L7.97 2.78945C8.76 2.31945 9.78 2.59945 10.25 3.38945L10.36 3.57945C11.26 5.14945 12.74 5.14945 13.65 3.57945L13.76 3.38945C14.23 2.59945 15.25 2.31945 16.04 2.78945L17.77 3.77945C18.68 4.29945 18.99 5.46945 18.47 6.36945C17.56 7.93945 18.3 9.21945 20.11 9.21945C21.15 9.21945 22.01 10.0694 22.01 11.1194V12.8794C22.01 13.9194 21.16 14.7794 20.11 14.7794C18.3 14.7794 17.56 16.0594 18.47 17.6294C18.99 18.5394 18.68 19.6994 17.77 20.2194L16.04 21.2094C15.25 21.6794 14.23 21.3994 13.76 20.6094L13.65 20.4194C12.75 18.8494 11.27 18.8494 10.36 20.4194L10.25 20.6094C9.78 21.3994 8.76 21.6794 7.97 21.2094L6.24 20.2194C5.33 19.6994 5.02 18.5294 5.54 17.6294C6.45 16.0594 5.71 14.7794 3.9 14.7794C2.85 14.7794 2 13.9194 2 12.8794V11.1194Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
</template>

<script>
import PwIconCopy from '@/components/Form/PwIconCopy';
import ModalTooltip from '@/components/Modals/ModalTooltip';

export default {
    name: 'BotCard',
    components: {
        PwIconCopy,
        ModalTooltip,
    },
    props: {
        status: {
            default: 'notConnected',
            type: String,
        },
        login: {
            default: '',
            type: String,
        },
        firstName: {
            default: '',
            type: String,
        },
        social: {
            default: 'Telegram',
            type: String,
        },
        image: {
            default: '',
            type: String,
        },
    },
    computed: {
        statusStyle() {
            if (this.status == 'connected') {
                return {
                    background: 'linear-gradient(101.32deg, #40B3E0 1.84%, #406DE0 100%)',
                };
            } else if (this.status == 'notConnected') {
                return {
                    background: 'linear-gradient(101.32deg, #C9D3D7 1.84%, #9BB5CE 100%)',
                };
            } else {
                return {
                    background: '#FF7C7C',
                };
            }
        },
    },
    methods: {
        goToAddBot() {
            this.$emit('isShow', false);
            this.$router.push({ name: 'add_bot' });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.bot-card {
    .fontello-icon {
        &-copy,
        &-link {
            font-size: 24px;
        }

        &-link {
            transform: rotate(-45deg);
            display: block;
        }

        &-copy {
            margin-left: 11px;
            margin-right: 11px;

            @include respond-below(xxs) {
                margin-left: 9px;
                margin-right: 10px;
            }
        }
    }

    &__start-chat {
        min-width: 170px;
        height: 36px;

        @include respond-below(xxs) {
            width: calc(100% - 100px);
        }
    }

    &__settings {
        cursor: pointer;
        transition: stroke 200ms;
        stroke: #52a7f9;
        margin-left: 11px;

        @include respond-below(xxs) {
            margin-left: 9px;
        }

        &:hover,
        &:active {
            stroke: #0071f4;
        }
    }
}
</style>

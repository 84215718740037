<template lang="pug">
	b-modal#modalwizardsuccess.wizard-success(ref='modal' :centered="!isMobile" :scrollable="isMobile" content-class="modal-max-content" hide-header body-class="p-0 d-flex flex-column" hide-footer)
		.d-flex.flex-column.align-items-center.wizard-success__content
			icon-close.wizard-success__close(v-if="isMobile" @click="close")
			img.mb-3.wizard-success__img(src="/assets/img/current/success-monitor.png" alt="Success monitor")
			h2.text-center.mb-3 {{ $t('wizard.successTitle') }}
			h3.mb-4.text-center(:class="{'b3': isMobile}") {{ $t('wizard.successDesc') }}
			.btn.btn-primary.w-100(@click="close") {{ $t('button.forward') }}
</template>

<script>
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'ModalWizardSuccess',
    components: {
        IconClose,
    },
    data: () => ({
        isShow: false,
    }),
    methods: {
        close() {
            this.$refs.modal.hide();

            this.$router.push({ name: 'project_settings', query: { tab: 4 } });
        },
    },
    watch: {
        isShow: {
            handler(nV, oV) {
                if (nV != null) {
                    return this.$refs.modal.show();
                }

                if (this.$refs.modal) this.$refs.modal.hide();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.wizard-success {
    &__content {
        position: relative;
        padding: 20px;
        width: 507px;

        @include respond-below(sm) {
            padding: 0;
            width: 100%;
        }
    }

    &__img {
        display: block;
        width: 169px;
        height: 169px;

        @include respond-below(sm) {
            width: 140px;
            height: 140px;
        }
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 20px;
    }
}
</style>
